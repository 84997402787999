import { useState } from 'react'

import styled from 'styled-components'

import Button from 'components/Button'
import Hide from 'components/Hide'
import Icon from 'components/Icon'
import Image from 'components/Image'
import Link from 'components/Link'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import FullWidthBackground from 'containers/FullWidthBackground'
import { trackPageCTABannerClicked } from 'lib/analyticsApi'
import finimizeLogoPrimary from 'public/images/finimize-primary-logo.svg'
import { routeMap } from 'shared/routes'
import { fonts } from 'theme/index'

const CTA_TEXT = 'Understand the markets in minutes.'
const CTA_SUBTEXT = 'Become a smarter investor.'
const CTA_DESTINATION = routeMap.join

const BannerCallToAction = () => {
    const [isExpanded, setIsExpanded] = useState(true)

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    const CTAButton = (
        <Button
            size="small"
            icon="RiArrowRightLine"
            iconPosition="right"
            onClick={trackPageCTABannerClicked}
            url={CTA_DESTINATION}
            ariaLabel="Subscribe to finimize"
        >
            Subscribe now
        </Button>
    )

    const expandableFlexDirection = isExpanded ? 'column' : 'row'
    const expandableFontSize = isExpanded ? fonts.size.L : fonts.size.M
    const expandableMarginBottom = isExpanded ? '' : '0px !important'

    return (
        <FullWidthBackground sticky backgroundColor="highlight.500">
            <Box py={1} px={2} width="100%" data-cy="call-to-action-banner">
                <Box display="flex" flexDirection={'row'} width="100%" alignItems="center">
                    <Box
                        display="flex"
                        flex={1}
                        alignItems={['left', 'left', 'left', 'center', 'center']}
                        justifyContent="left"
                        flexDirection={[
                            expandableFlexDirection,
                            expandableFlexDirection,
                            expandableFlexDirection,
                            'row',
                            'row',
                        ]}
                    >
                        <Image
                            src={finimizeLogoPrimary}
                            maxWidth="24px"
                            alt="Finimize logo icon"
                            priority={true}
                            mt={['0.2rem', '0.2rem', '0.2rem', '0rem', '0rem']}
                            mb={['0.5rem', '0.5rem', '0.5rem', '0rem', '0rem']}
                            mr={['0.5rem', '0.5rem', '0.5rem', '0rem', '0rem']}
                        />
                        <Box>
                            <Paragraph
                                fontWeight={fonts.weight.bold}
                                fontSize={[
                                    expandableFontSize,
                                    expandableFontSize,
                                    expandableFontSize,
                                    fonts.size.M,
                                    fonts.size.M,
                                ]}
                                marginBottom={[
                                    expandableMarginBottom,
                                    expandableMarginBottom,
                                    expandableMarginBottom,
                                    '0px !important',
                                    '0px !important',
                                ]}
                                marginLeft={[0, 0, 0, 1, 1]}
                            >
                                {CTA_TEXT}
                            </Paragraph>
                            <Hide on={[false, false, false, true, true]}>
                                {isExpanded ? (
                                    CTAButton
                                ) : (
                                    <TextLink
                                        onClick={trackPageCTABannerClicked}
                                        url={CTA_DESTINATION}
                                    >
                                        Subscribe now
                                    </TextLink>
                                )}
                            </Hide>
                        </Box>
                        <Hide on={[true, true, true, true, false]}>
                            <Paragraph
                                fontWeight={fonts.weight.normal}
                                fontSize={fonts.size.S}
                                marginBottom="0px !important"
                                marginLeft={1}
                            >
                                {CTA_SUBTEXT}
                            </Paragraph>
                        </Hide>
                    </Box>
                    <Hide on={[false, false, false, true, true]}>
                        <ArrowIcon
                            icon="RiArrowDownSLine"
                            color="neutral.999"
                            size="medium"
                            onClick={toggleExpanded}
                            $isExpanded={isExpanded}
                        />
                    </Hide>
                    <Hide on={[true, true, true, false, false]}>{CTAButton}</Hide>
                </Box>
            </Box>
        </FullWidthBackground>
    )
}

const ArrowIcon = styled(Icon)<{ $isExpanded: boolean }>`
    transform: ${({ $isExpanded }) => ($isExpanded ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 0.3s;
    cursor: pointer;
`

const TextLink = styled(Link)`
    color: ${({ theme }) => theme.palette.neutral['999']};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    text-decoration: underline !important;
    &:hover {
        color: ${({ theme }) => theme.palette.primary['700']};
    }
`

export default BannerCallToAction
