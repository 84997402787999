import assert from 'assert'

import { useEffect } from 'react'

import { graphql, useFragment } from 'react-relay'

import type { useEnrollInExperiment_viewer$key } from '__generated__/useEnrollInExperiment_viewer.graphql'
import { handleExperimentSpecificEffect } from 'components/Experiments/handler'
import useExperiments from 'components/Experiments/useExperiments'
import { registerExperiment, trackExperimentStarted } from 'lib/analyticsApi'
import { useCookies } from 'lib/useCookies'
import useHasLoadedOnClient from 'lib/useHasLoadedOnClient'

/*

    useEnrollInExperiment

    Given an experiment name plus the variants built into the UI,
    this hook will decide which variant to show (handling edge cases) 
    as well as handle the tracking

    Ideally you should only use this hook in one place, otherwise the 
    tracking event will be duplicated. Up to you to decide if this is a 
    problem or not!

*/

export type Props = {
    name: string
    viewer: useEnrollInExperiment_viewer$key
    /**
     * the default variant to use if the user is not enrolled in the experiment. This is
     * important as this will be used if experiment is turned off
     */
    defaultVariantName: string
    /**
     * the variants that are available in the UI.
     * Put null if there are no variants built client-side
     */
    variantsAvailableInUI: string[] | null
}

export type UseEnrollInExperimentReturnVal = [string, boolean]

const useEnrollInExperiment = ({
    name,
    viewer: viewerKey,
    defaultVariantName,
    variantsAvailableInUI,
}: Props): UseEnrollInExperimentReturnVal => {
    const viewer = useFragment(ExperimentsFragment, viewerKey)
    const { getVariantFor } = useExperiments(viewer)
    const { setCookie } = useCookies()
    // Variant can change between static & client-side data loading, so only trigger tracking
    // once client is loaded
    const hasLoadedOnClient = useHasLoadedOnClient()

    const variantFromServer = getVariantFor(name)
    const enrolledInExperiment = !!variantFromServer

    let usedVariantName: string
    if (variantsAvailableInUI !== null) {
        assert(
            variantsAvailableInUI.includes(defaultVariantName),
            `Default experiment variant ${defaultVariantName} was not in available options for ${name}`,
        )
        usedVariantName =
            variantFromServer && variantsAvailableInUI.includes(variantFromServer.name)
                ? variantFromServer.name
                : defaultVariantName
    } else {
        usedVariantName = variantFromServer?.name ?? defaultVariantName
    }

    useEffect(() => {
        if (enrolledInExperiment) {
            const { isInitialized: isExperimentInitialized } = handleExperimentSpecificEffect({
                typeName: name,
                variant: variantFromServer,
                setCookie,
            })

            if (isExperimentInitialized && hasLoadedOnClient) {
                // TODO: we are going with both the super props and the $experiment_started event approaches
                // If one is better than the other, we should remove the other
                trackExperimentStarted({ experimentName: name, usedVariantName })
                registerExperiment({ experimentName: name, usedVariantName })
            }
        }
    }, [enrolledInExperiment, name, usedVariantName, hasLoadedOnClient])

    return [usedVariantName, hasLoadedOnClient]
}

export default useEnrollInExperiment

export const ExperimentsFragment = graphql`
    fragment useEnrollInExperiment_viewer on Viewer {
        experiments {
            name
            variant {
                name
                config {
                    maxContentPieceAllowance
                }
            }
        }
    }
`
