import React from 'react'

import { LegacyLink as Link } from 'components/Link'
import Box from 'components/Primitives/Box'
import type { Spacing } from 'components/Set'
import Set from 'components/Set'
import { fonts } from 'theme'

type Props = {
    links: { title: string; url: string }[]
    isVertical?: boolean
    spacing?: Spacing
}

const FooterSectionLinks = (props: Props) => {
    const { links, isVertical = true, spacing } = props
    const Links = links.map((link, index) => (
        <Link
            color="neutral.600"
            fontSize={fonts.size.XS}
            fontWeight={fonts.weight.normal}
            url={link.url}
            data-cy={`footer-link-${link.title}`}
            key={index}
        >
            {link.title}
        </Link>
    ))
    return (
        <Box paddingRight={[0, 0, 2]} paddingBottom={0}>
            <Set isVertical={isVertical} spacing={spacing}>
                {Links}
            </Set>
        </Box>
    )
}

export default FooterSectionLinks
