import styled, { css } from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { layout } from 'theme'

export type ContainerProps = {
    children?: React.ReactNode
    isFluid?: boolean
} & Omit<
    BoxProps,
    'width' | 'margin' | 'box-sizing' | 'padding-left' | 'pl' | 'padding-right' | 'pr' | 'px'
>

export const Container = ({ children, className, isFluid, ...props }: ContainerProps) => (
    <_Container isFluid={isFluid} className={className} {...props}>
        {children}
    </_Container>
)

export const ContainerDefaultProps: Partial<ContainerProps> = {
    className: undefined,
    isFluid: false,
}

Container.defaultProps = ContainerDefaultProps

const _Container = styled(Box)<ContainerProps>`
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    ${props =>
        !props.isFluid &&
        css`
            max-width: ${layout.breakpoints.xLarge};
        `}

    ${props =>
        props.isFluid &&
        css`
            padding-left: 2rem;
            padding-right: 2rem;
        `}

    @media (max-width: ${layout.breakpoints.xLarge}) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
`

export default Container
