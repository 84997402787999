import { useState, useEffect } from 'react'

/**
 * Use this to assert that a component is loaded on the client side.
 */
const useHasLoadedOnClient = () => {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        // This is set when the page has been hydrated.
        setIsClient(true)
    }, [])

    return isClient
}

export default useHasLoadedOnClient
