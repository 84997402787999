import { useCallback, useState } from 'react'

const useNavBarMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const onNavBarToggle = useCallback((toggled: boolean) => {
        setIsMenuOpen(toggled)

        const body = document?.querySelector('body')

        if (body) {
            const isToggled = toggled ? 'hidden' : 'visible'
            body.style.overflow = isToggled
        }
    }, [])

    return { isMenuOpen, onNavBarToggle }
}

export default useNavBarMenu
