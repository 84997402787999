import { useCallback, useEffect, useRef } from 'react'

import Hamburger from 'hamburger-react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { getNavBarItems, HOME_LOGO_TRACKING_NAME } from '../NavBarLinks'
import NavLogin from '../NavLogin'
import useNavBarMenu from '../useNavBarMenu'

import Button from 'components/Button'
import Image from 'components/Image'
import { LegacyLink as Link } from 'components/Link'
import NavigationItem from 'components/NavigationItem'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import {
    CTAPlacement,
    trackConversionCTACLicked,
    trackNavigationItemClicked,
} from 'lib/analyticsApi'
import { useButtonTextFromURLParams } from 'modules/ContentDisplay/utils'
import SearchBar from 'modules/Search/SearchBar'
import finimizeLogoPrimaryFull from 'public/images/finimize-primary-logo-full.svg'
import { routeMap } from 'shared/routes'
import { palette, radii } from 'theme'

interface NavBarMobileProps {
    isAuthenticated: boolean
    isSearchPage: boolean
    freeTrialUrl: string
    showPurchaseCTA: boolean
    isB2BUser: boolean
}

const NavMobile = ({
    isAuthenticated,
    showPurchaseCTA,
    freeTrialUrl,
    isSearchPage,
    isB2BUser,
}: NavBarMobileProps) => {
    const { isMenuOpen, onNavBarToggle } = useNavBarMenu()

    const setNavBarClose = useCallback((item?: string) => {
        if (item) trackNavigationItemClicked(item)
        onNavBarToggle(false)
    }, [])

    // Scroll to top of menu when it opens
    const menuContainerRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (isMenuOpen && menuContainerRef.current) {
            menuContainerRef.current.scrollTo(0, 0)
        }
    }, [isMenuOpen])

    // Close the menu when we navigate to a new page
    const router = useRouter()
    useEffect(() => {
        const handleRouteChange = () => {
            setNavBarClose()
        }

        router.events.on('routeChangeComplete', handleRouteChange)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events, setNavBarClose])

    const CTAButtonText = useButtonTextFromURLParams()

    const homeLogoClicked = () => setNavBarClose(HOME_LOGO_TRACKING_NAME)
    const CTAClicked = () => {
        trackConversionCTACLicked({ placement: CTAPlacement.NAV_BAR })
        setNavBarClose('CTA')
    }

    return (
        <>
            <Header>
                <Set justifyContent="space-between" alignItems="center">
                    <IconWrapper borderRadius={radii.default}>
                        <Hamburger
                            color={palette.neutral[999]}
                            onToggle={onNavBarToggle}
                            size={20}
                            label="Menu"
                            toggled={isMenuOpen}
                        />
                    </IconWrapper>
                    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
                        <Link url={routeMap.home} onClick={homeLogoClicked}>
                            <Image
                                src={finimizeLogoPrimaryFull}
                                alt="Finimize"
                                width="35vw"
                                maxWidth="10rem"
                                pt="0.5rem"
                                priority={true}
                            />
                        </Link>
                    </Box>

                    {showPurchaseCTA ? (
                        <CTAButton
                            variant="primary"
                            size="small"
                            url={freeTrialUrl}
                            onClick={CTAClicked}
                        >
                            {CTAButtonText}
                        </CTAButton>
                    ) : (
                        // Provides placeholder spacing equal to the hamburger
                        // which helps to align the logo in the centre.
                        <IconWrapper />
                    )}
                </Set>
            </Header>

            <MenuContainer ref={menuContainerRef} $isMenuOpen={isMenuOpen}>
                {!isSearchPage && (
                    <SearchBarContainer>
                        <Box>
                            <SearchBar isAuthenticated={isAuthenticated} />
                        </Box>
                    </SearchBarContainer>
                )}
                <Set isVertical alignItems="center">
                    <NavBarItemsAndTagsContainer>
                        <Set isVertical spacing={0.25}>
                            {navBarItems({ onClick: setNavBarClose, isB2BUser, isAuthenticated })}
                        </Set>
                    </NavBarItemsAndTagsContainer>

                    {!isAuthenticated && <NavLogin isAuthenticated={isAuthenticated} />}
                </Set>
            </MenuContainer>
        </>
    )
}

const navBarItems = ({
    onClick,
    isB2BUser,
    isAuthenticated,
}: {
    isB2BUser: boolean
    isAuthenticated: boolean
    onClick: (item: string) => void
}) =>
    getNavBarItems({ isB2BUser, showProfile: isAuthenticated }).map(header => {
        const trackClick = () => onClick(header.name)
        return (
            <NavigationItem
                key={header.name}
                name={header.name}
                icon={header.icon}
                url={header.url}
                onClick={trackClick}
            />
        )
    })

const Header = styled(Box)`
    position: sticky;
    background: ${({ theme }) => theme.palette.neutral[0]};
    display: flex;
    flex-direction: column;
    right: 0;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.layer04};
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: ${({ theme }) =>
        theme.zIndex.layer06}; // has to be higher than zIndex of menuPortal in SearchFilter
    box-shadow: 0px 1px 1px ${({ theme }) => theme.palette.neutral[200]};
    @media (max-width: ${({ theme }) => theme.layout.breakpoints.small}) {
        padding-left: 0rem;
        padding-right: 0.5rem;
    }
`

const SearchBarContainer = styled(Box)<BoxProps>`
    //  position:relative is needed to control the
    //  positioning and width of the search dropdown
    position: relative;
    padding: 1rem;
    background: ${({ theme }) => theme.palette.neutral[50]};
`

const NavBarItemsAndTagsContainer = styled.div`
    max-width: 400px; // Edge case for very wide phone screens
    @media (min-width: ${({ theme }) => theme.layout.breakpoints.medium}) {
        max-width: 40vw;
    }
`

const MENU_CONTAINER_TOP = '64px'

const MenuContainer = styled(Box)<{ $isMenuOpen: boolean }>`
    width: 100vw;
    // Viewport height minus the header height. Enables scrolling when content overflows
    max-height: calc(100vh - ${MENU_CONTAINER_TOP});
    // Fill up the available space
    height: 100%;

    // For tablet screens, menu takes up the whole screen
    @media (min-width: ${({ theme }) => theme.layout.breakpoints.medium}) {
        min-height: 100vh;
        height: auto;
    }

    // Add scrolling to overflowing content
    overflow-y: auto;

    padding: 1rem;
    background: ${({ theme }) => theme.palette.neutral[50]};
    position: fixed;
    z-index: ${({ theme }) =>
        theme.zIndex.layer06}; // has to be higher than zIndex of menuPortal in SearchFilter
    transform: ${({ $isMenuOpen }) =>
        $isMenuOpen ? `translate3d(0, 0, 0)` : `translate3d(-100vw, 0, 0)`};
    transition: transform 0.4s cubic-bezier(0, 0.52, 0, 1);
`

const IconWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3rem;
    min-width: 3rem;
    background-color: ${({ theme }) => theme.palette.neutral[0]};
    color: ${({ theme }) => theme.palette.neutral[0]};
    svg {
        color: ${({ theme }) => theme.palette.neutral[999]};
    }
`

const CTAButton = styled(Button)`
    margin-left: 1rem;
    @media (max-width: ${({ theme }) => theme.layout.breakpoints.small}) {
        margin-left: 0rem;
    }
`

export default NavMobile
