/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DefaultLayout_viewer = {
    readonly authorization: {
        readonly " $fragmentRefs": FragmentRefs<"Navigation_authorization">;
    };
    readonly " $fragmentRefs": FragmentRefs<"useEnrollInExperiment_viewer">;
    readonly " $refType": "DefaultLayout_viewer";
};
export type DefaultLayout_viewer$data = DefaultLayout_viewer;
export type DefaultLayout_viewer$key = {
    readonly " $data"?: DefaultLayout_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"DefaultLayout_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DefaultLayout_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Authorization",
      "kind": "LinkedField",
      "name": "authorization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Navigation_authorization"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useEnrollInExperiment_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '302a26cb42797c2ceb1e7fbd0761649f';
export default node;
