import { createContext, useContext, useMemo } from 'react'

import type { UseEnrollInExperimentReturnVal } from './useEnrollInExperiment'
import useEnrollInExperiment from './useEnrollInExperiment'

import type { useEnrollInExperiment_viewer$key } from '__generated__/useEnrollInExperiment_viewer.graphql'
import { captureException } from 'shared/sentry'

/**
 *
 * To avoid triggering the enrollInExperiment tracking many times on a page
 * where the variants appear lots of times, you can use this hook to store the
 * experiment variants on context and therefore only trigger the tracking once.
 */

type ExperimentContextProps = {
    experimentName: string
    defaultVariantName: string
    variantsAvailableInUI: string[]
}

// We want to provide a clear fallback for if a provider was not added.
// But also want to make it easy to destructure the return value
type ContextReturnValue = UseEnrollInExperimentReturnVal | [null, null]

export function createExperimentContext({
    experimentName,
    defaultVariantName,
    variantsAvailableInUI,
}: ExperimentContextProps) {
    const ExperimentContext = createContext<ContextReturnValue>([null, null])

    const useExperimentContext = (): ContextReturnValue => {
        const context = useContext(ExperimentContext) ?? null

        if (!context || context[0] === null) {
            // If there's no provider, we likely want the page to not crash, but we want to alert Sentry and fail
            // any cypress tests
            const errorMessage = `useExperimentContext context was used without being wrapped in a ${experimentName} Provider`
            console.error(errorMessage)
            captureException(new Error(errorMessage))
        }
        return context
    }

    interface ExperimentProviderProps {
        viewer: useEnrollInExperiment_viewer$key
        children: React.ReactNode
    }

    const ExperimentProvider: React.FC<ExperimentProviderProps> = ({ children, viewer }) => {
        const hookReturn = useEnrollInExperiment({
            name: experimentName,
            viewer,
            defaultVariantName,
            variantsAvailableInUI,
        })

        const experimentData = useMemo(() => hookReturn, [...hookReturn])

        return (
            <ExperimentContext.Provider value={experimentData}>
                {children}
            </ExperimentContext.Provider>
        )
    }

    return { ExperimentProvider, useExperimentContext }
}
