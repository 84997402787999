import React from 'react'

import { keyframes } from 'styled-components'
import { css } from 'styled-components'

import Image from 'components/Image'
import spinnerGif from 'public/images/loading-spinner.gif'

export const SPINNER_A11Y_LABEL = 'Content is loading and showing spinner'

const defaultSize = 50

/**
 * A loading spinner. Render it as a placeholder for still-loading views.
 *
 * Takes no props & can't e.g. hide itself. This is a spinner. It spins.
 *
 * @example
 * const [stuff, setStuff] = React.useState(null);
 * React.useEffect(() => loadStuff().then(stuff => setStuff(stuff), [])
 * return stuff != null ? <List data={data} /> : <Spinner />
 */
export const Spinner = ({ size }: { size?: number }) => {
    return (
        <Image
            aria-label={SPINNER_A11Y_LABEL}
            alt={SPINNER_A11Y_LABEL}
            css={spinnerAnimation}
            src={spinnerGif}
            height={size ? size : defaultSize}
            width={size ? size : defaultSize}
        />
    )
}

const spinnerAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const cssCardAnimation = css`
    animation-name: ${spinnerAnimation};
    animation-duration: 50ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`
