import { FINANCEADS_ID_PARAM } from './tracking/financeAds/consts'
import { IMPACT_CLICK_ID_PARAM, IMPACT_IRGWC_PARAM } from './tracking/impact/consts'

/**
 * Creates a url param to turn the current page path into a ref-param for the next page.
 * This allows the next page to know where the user came from, and return them to that page once the action is complete.
 *
 * @param asPath The current page path, can be found in the router object.
 * @returns An encoded uri component string that can be appended to a url.
 */
export const createPostCheckoutRedirectParam = (asPath: string): string => {
    const filteredPath = removeFilteredParamsFromPath(asPath)
    return `ref-page=${encodeURIComponent(filteredPath)}`
}

/**
 * This is a list of params that should be filtered out
 */
export const FILTERED_PARAMS = [FINANCEADS_ID_PARAM, IMPACT_CLICK_ID_PARAM, IMPACT_IRGWC_PARAM]

/**
 * Filters out params in FILTERED_PARAMS from asPath.
 * Not removing these causes a redirect to a wrong page and a 404 when a ref-page param is created.
 *
 * @param asPath The current page path, can be found in the router object.
 * @returns The asPath with the filtered params removed.
 */
export const removeFilteredParamsFromPath = (asPath: string): string => {
    const [path, query] = asPath.split('?')
    const queryParams = query
        ? query
              .split('&')
              .filter(param => !FILTERED_PARAMS.some(removedParam => param.includes(removedParam)))
              .join('&')
        : ''

    return path + (queryParams ? `?${queryParams}` : '')
}

/**
 * Creates a url param for the cta source, this can be used for tracking purposes.
 */
export const createCTASourceUrlParam = (source: string): string => {
    return `cta-source=${source}`
}
