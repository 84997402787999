import React from 'react'

import styled from 'styled-components'

import Icon from 'components/Icon'
import type { IconProps } from 'components/Icon'
import { LegacyLink as Link } from 'components/Link'
import Set from 'components/Set'

const ShareIconButton = styled(Icon)<IconProps>`
    &:hover {
        color: #2f80ed;
        text-decoration: none !important;
        transition: 0.3s;
        cursor: pointer;
    }
`

const FooterSocialLink = () => {
    const FB_SHARE_LINK = 'https://www.facebook.com/finimize/'
    const INSTA_SHARE_LINK = 'https://www.instagram.com/finimize/'
    const TWITTER_SHARE_LINK = 'https://twitter.com/finimize'
    const LINKEDIN_SHARE_LINK = 'https://www.linkedin.com/company/finimize/'
    const MEDIUM_SHARE_LINK = 'https://finimize.medium.com'

    return (
        <Set spacing={2} display="flex" alignItems="flex-start">
            <Link url={INSTA_SHARE_LINK} aria-label="Instagram Share Link">
                <ShareIconButton icon="RiInstagramFill" color="neutral.400" size="default" />
            </Link>
            <Link url={TWITTER_SHARE_LINK} aria-label="Twitter Share Link">
                <ShareIconButton icon="RiTwitterXFill" color="neutral.400" size="default" />
            </Link>
            <Link url={FB_SHARE_LINK} aria-label="Facebook Share Link">
                <ShareIconButton icon="RiFacebookFill" color="neutral.400" size="default" />
            </Link>
            <Link url={LINKEDIN_SHARE_LINK} aria-label="LinkedIn Share Link">
                <ShareIconButton icon="RiLinkedinFill" color="neutral.400" size="default" />
            </Link>
            <Link url={MEDIUM_SHARE_LINK} aria-label="Medium Share Link">
                <ShareIconButton icon="RiMediumFill" color="neutral.400" size="default" />
            </Link>
        </Set>
    )
}

export default FooterSocialLink
