import { createCTASourceUrlParam, createPostCheckoutRedirectParam } from './commonPageUrlParam'

import { routeMap } from 'shared/routes'

const getJoinPageUrlPath = (asPath: string, ctaSource: string): string => {
    return `${routeMap.join}?${createPostCheckoutRedirectParam(asPath)}&${createCTASourceUrlParam(
        ctaSource,
    )}`
}

export { getJoinPageUrlPath }
