import styled from 'styled-components'

import FooterSectionLinks from './FooterSectionLinks'

import Hide from 'components/Hide'
import Image from 'components/Image'
import { TextLink as Link } from 'components/Link'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import FullWidthBackground from 'containers/FullWidthBackground'
import FooterSocialLinks from 'modules/FooterSocialLinks'
import finimizePrimaryLogoFull from 'public/images/finimize-primary-logo-full.svg'
import { routeMap } from 'shared/routes'
import type { ColorName } from 'theme'
import Theme, { fonts } from 'theme'

type FooterProps = {
    bgColor?: ColorName
}

const footerLinksSpacing = [1, 2]

export const Footer = ({ bgColor }: FooterProps) => (
    <FullWidthBackground backgroundColor={bgColor || 'neutral.0'}>
        <Box
            paddingY={2}
            paddingX={[3, 3, 4]}
            backgroundColor={bgColor}
            data-cy="footer"
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems={['flex-start', 'flex-start', 'flex-end']}
        >
            <LogoImage />
            <Set display="flex" justifyContent="space-between" alignItems="flex-start" spacing={4}>
                <FooterSectionLinks
                    links={[
                        { title: 'Home', url: '/' },
                        { title: 'For Business', url: routeMap.business },
                        { title: 'Careers', url: routeMap.careers },
                        { title: 'Glossary', url: routeMap.glossary },
                    ]}
                    spacing={footerLinksSpacing}
                />
                <FooterSectionLinks
                    links={[
                        { title: 'About Us', url: routeMap.about },
                        { title: 'Terms of Use', url: routeMap.terms },
                        { title: 'Privacy Policy', url: routeMap.privacy },
                        { title: 'Help', url: routeMap.faq },
                    ]}
                    spacing={footerLinksSpacing}
                />
            </Set>
            <Set isVertical alignItems={['flex-start', 'flex-start', 'flex-end']} paddingTop={1}>
                <FooterSocialLinks />
                <LegalText>
                    © Finimize Ltd. {new Date().getFullYear()} 10328011. 280 Bishopsgate, London,
                    EC2M 4AG
                </LegalText>
            </Set>
        </Box>
    </FullWidthBackground>
)

const LogoImage = () => (
    <Hide on={[false, false, true]} marginBottom={2} paddingRight={[null, null, 3]}>
        <Link url={{ pathname: routeMap.home }}>
            <Image src={finimizePrimaryLogoFull} alt="Finimize" width="180px" />
        </Link>
    </Hide>
)

const LegalText = styled.div`
    color: ${Theme.palette.neutral[600]};
    font-size: ${fonts.size.XXS};
`

export default Footer
