/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useEnrollInExperiment_viewer = {
    readonly experiments: ReadonlyArray<{
        readonly name: string;
        readonly variant: {
            readonly name: string;
            readonly config: {
                readonly maxContentPieceAllowance: number | null;
            } | null;
        };
    }>;
    readonly " $refType": "useEnrollInExperiment_viewer";
};
export type useEnrollInExperiment_viewer$data = useEnrollInExperiment_viewer;
export type useEnrollInExperiment_viewer$key = {
    readonly " $data"?: useEnrollInExperiment_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"useEnrollInExperiment_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEnrollInExperiment_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Experiment",
      "kind": "LinkedField",
      "name": "experiments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Variant",
          "kind": "LinkedField",
          "name": "variant",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "VariantConfig",
              "kind": "LinkedField",
              "name": "config",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxContentPieceAllowance",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = '2f4392098651d05ae74ed8fe45e0de59';
export default node;
