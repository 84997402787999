import type { ReactElement } from 'react'

import type { NextSeoProps } from 'next-seo'
import { NextSeo } from 'next-seo'

import { isProductionEnvironment } from 'utils/isProductionEnvironment'

export interface SeoProps extends NextSeoProps {
    noindex: NonNullable<NextSeoProps['noindex']>
}

type Props = {
    children: ReactElement
    /**
     * These will overwrite any default values provided in _app.tsx
     */
    seoProps: SeoProps
}

const SeoWrapper = ({ children, seoProps }: Props) => {
    const { openGraph, title, titleTemplate, description, canonical, noindex, ...otherSeoProps } =
        seoProps

    const fullTitle = titleTemplate && title ? titleTemplate.replace('%s', title) : title

    // Stops indexing and following on non-production environments
    const isNoIndex = isProductionEnvironment ? noindex : true
    const isNoFollow = !isProductionEnvironment

    const updatedOpenGraph = {
        title: fullTitle,
        description,
        url: canonical,
        ...openGraph,
    }

    return (
        <>
            <NextSeo
                title={title}
                titleTemplate={titleTemplate}
                description={description}
                canonical={canonical}
                openGraph={updatedOpenGraph}
                noindex={isNoIndex}
                nofollow={isNoFollow}
                {...otherSeoProps}
            />
            {children}
        </>
    )
}

export default SeoWrapper
