import type { ReactElement, ReactFragment } from 'react'
import { useMemo } from 'react'

import { graphql, useFragment } from 'react-relay'
import styled from 'styled-components'

import MaxWidthContainer from '../containers/MaxWidthContainer'

import type { SeoProps } from './SeoWrapper'
import SeoWrapper from './SeoWrapper'

import type { DefaultLayout_viewer$key } from '__generated__/DefaultLayout_viewer.graphql'
import { PremiumContentExperimentProvider } from 'components/Experiments/experimentContexts'
import Box from 'components/Primitives/Box'
import useHasLoadedOnClient from 'lib/useHasLoadedOnClient'
import BannerCallToAction from 'modules/BannerCallToAction'
import Footer from 'modules/Footer/Footer'
import Navigation from 'modules/Navigation'
import type { ColorName } from 'theme'

type LayoutProps = {
    children: ReactElement | ReactFragment
    isAuthenticated: boolean
    isSearchPage?: boolean
    hasPremiumSubscription: boolean
    viewer: DefaultLayout_viewer$key | null
    seoProps: SeoProps
    hideFooter?: boolean
    hideNav?: boolean
    bgColor?: ColorName
    showCTABanner?: boolean
}

// If you add any fragments to this component, you will need to make sure that
// ALL the pages that use it are wrapped in a Relay environment (e.g. withData)
// or they will error.
const DefaultLayout = ({
    children: pageContent,
    isAuthenticated,
    isSearchPage = false,
    hasPremiumSubscription,
    viewer: viewerKey,
    hideFooter = false,
    hideNav = false,
    seoProps,
    bgColor = 'neutral.0',
    showCTABanner: enabledCTABanner = false,
}: LayoutProps): React.ReactElement => {
    const viewer = useFragment(DefaultLayoutFragment, viewerKey)

    const hasLoadedOnClient = useHasLoadedOnClient()

    const showCTABanner = enabledCTABanner && hasLoadedOnClient && !hasPremiumSubscription

    const PageAndFooter = useMemo(() => {
        return (
            <>
                <Box minHeight="100vh">{pageContent}</Box>
                {!hideFooter && <Footer bgColor={bgColor} />}
            </>
        )
    }, [bgColor, hideFooter, pageContent])

    const InnerContent = (
        <SeoWrapper seoProps={seoProps}>
            <Page>
                {!hideNav ? (
                    <Navigation
                        hasPremiumSubscription={hasPremiumSubscription}
                        isAuthenticated={isAuthenticated}
                        authorization={viewer?.authorization ?? null}
                        isSearchPage={isSearchPage}
                    >
                        <MaxWidthContainer centraliseChildren>{PageAndFooter}</MaxWidthContainer>
                    </Navigation>
                ) : (
                    <>{PageAndFooter}</>
                )}
                {showCTABanner && <BannerCallToAction />}
            </Page>
        </SeoWrapper>
    )

    // Can get rid of this wrapper once the premium content display experiment is complete
    // This is required on any page that has a content piece card, so easiest to add into the layout
    // component. Requires viewer to get the experiment variants.
    return !!viewer ? (
        <PremiumContentExperimentProvider viewer={viewer}>
            {InnerContent}
        </PremiumContentExperimentProvider>
    ) : (
        InnerContent
    )
}

/**
 * We have to be really careful here with static pages since this is a general wrapper.
 * If someone added something which reacts to client side data ensure you test on a static page
 * as it could quite drastically impact with UX/layout shift.
 */
const DefaultLayoutFragment = graphql`
    fragment DefaultLayout_viewer on Viewer {
        ...useEnrollInExperiment_viewer
        authorization {
            ...Navigation_authorization
        }
    }
`

const Page = styled(Box)`
    min-height: 100vh;
    max-width: 100vw;
`

export default DefaultLayout
