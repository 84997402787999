import { createExperimentContext } from 'components/Experiments/createExperimentContext'
import { PREMIUM_CONTENT_PREVIEW } from 'data/experiments'

export const {
    ExperimentProvider: PremiumContentExperimentProvider,
    useExperimentContext: usePremiumContentExperimentContext,
} = createExperimentContext({
    experimentName: PREMIUM_CONTENT_PREVIEW.NAME,
    defaultVariantName: PREMIUM_CONTENT_PREVIEW.VARIANTS.DEFAULT,
    variantsAvailableInUI: Object.values(PREMIUM_CONTENT_PREVIEW.VARIANTS),
})
