/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Navigation_authorization = {
    readonly roles: {
        readonly isB2bUser: boolean;
    };
    readonly " $refType": "Navigation_authorization";
};
export type Navigation_authorization$data = Navigation_authorization;
export type Navigation_authorization$key = {
    readonly " $data"?: Navigation_authorization$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Navigation_authorization">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_authorization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleFlags",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isB2bUser",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Authorization",
  "abstractKey": null
};
(node as any).hash = '7f46dbba3840cd85e8d0631670cd1422';
export default node;
