import type { Variant } from './useExperiments'

import {
    MAX_FREE_CONTENT_PIECES_ALLOWANCE_EXPERIMENT_NAME,
    MAX_FREE_CONTENT_PIECES_ALLOWANCE_COOKIE_NAME,
} from 'lib/freeArticleAllowance/consts'
import type { SetCookie } from 'lib/useCookies'
import { captureException } from 'shared/sentry'
type MaxFreeContentPieceAllowanceExperimentProps = {
    typeName: string
    variant: Variant
    setCookie: SetCookie
}

// Here all the experiment specific props will be added to
type Props = MaxFreeContentPieceAllowanceExperimentProps

/**
 * This is a function for handling experiment specific effects.
 */
export const handleExperimentSpecificEffect = (props: Props) => {
    if (props.typeName === MAX_FREE_CONTENT_PIECES_ALLOWANCE_EXPERIMENT_NAME) {
        const experimentProps = props as MaxFreeContentPieceAllowanceExperimentProps
        const { variant, setCookie } = experimentProps
        if (variant.config?.maxContentPieceAllowance === undefined) {
            captureException(
                new Error(
                    `Experiment ${props.typeName} was missing maxContentPieceAllowance config`,
                ),
            )
            // no experiment enrollment if the config is missing
            return { isInitialized: false }
        }

        const maxFreeContentPiecesAllowance = Number(variant.config?.maxContentPieceAllowance)

        // TODO: this information could be stored in the local storage instead of a cookie
        setCookie(
            MAX_FREE_CONTENT_PIECES_ALLOWANCE_COOKIE_NAME,
            String(maxFreeContentPiecesAllowance),
        )
    }

    return { isInitialized: true }
}
